/**
 * External Dependencies
 */
/* eslint-disable */
import 'jquery';
import debounce from 'lodash/debounce';
import 'bootstrap';
import './jquery.truncate.min';
/* eslint-enable */

(function ($) {
  $(window).on('load', () => {
    function fixZIndex() {
      $('.section-marker-projekte').parents('.wp-block-group').css('z-index', '1');
    }

    fixZIndex();
  });

  $(document).ready(() => {
    // add span to standard headings because we need inline element for marking
    $('.is-style-conlabz-marked')
      .filter('h1,h2,h3,h4,h5,h6')
      .each((index, el) => {
        const current = $(el);
        current
          .html(`<span class="is-style-conlabz-marked">${current.html()}</span>`)
          .removeClass('is-style-conlabz-marked');
      });

    // truncate blog overview <p>s
    $('article.post .summary p').truncate({
      width: 'auto',
      token: '&hellip;',
      side: 'right',
      multiline: true,
      addclass: false,
      addtitle: false,
    });

    window.addEventListener(
      'resize',
      debounce(() => {
        const nav = $('#navbarVertical');
        const navWidth = nav.width();
        nav.css('right', `${navWidth * -1}px`);
      }, 50)
    );

    $('#toggleVertical').click(function () {
      $('body').toggleClass('overflow-hidden');
      $(this).toggleClass('expanded');
      $('#navbarVertical').toggleClass('show-vertical');
    });

    /**
     * disable google-maps from loading google-fonts
     */
    const head = document.getElementsByTagName('head')[0];
    const insertBefore = head.insertBefore;
    head.insertBefore = function (newElement, referenceElement) {
      if (newElement.href && newElement.href.indexOf('//fonts.googleapis.com') > -1) {
        return;
      }

      insertBefore.call(head, newElement, referenceElement);
    };
  });
})(jQuery);
